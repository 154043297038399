<template>
  <div class="homeView">
    <NavigationBar class="bar" />
    <div class="main">
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import NavigationBar from '@/components/navs/NavigationBar.vue';
export default {
  name: "HomeView",
  components: {
    NavigationBar
  }

}
</script>

<style lang="scss" scoped>
.line-height {
  line-height: 1;
}

.main {
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 1.6em;
}

.main::-webkit-scrollbar {
  width: 16px;

}

.main::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255) !important;
  /* color of the tracking area */
}



.nav {
  height: 12vh !important;
  background-color: rgb(255, 255, 255) !important;

}

.container {
  width: 100% !important;
}

.bar {
  z-index: 111111;
  padding: 10px 0 !important;
}


@media (max-width:920px) {
  .main::-webkit-scrollbar {
    display: none;
  }

  .nav {
    height: 20.5vh !important;
 

  }

  .main {
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 1.2em;
  }

}
@media (max-width:890px) {
  .main::-webkit-scrollbar {
    display: none;
  }

  

  .nav {
    height: 10vh !important;
   

  }
}
@media (max-width:820px) {
  .main{
    // margin-top: 2.9em ;
  }
}


@media (max-width:760px) and (orientation : landscape) {
  .main::-webkit-scrollbar {
    display: none;
  }

  .main{
    margin-top: 0 ;
  }

  .nav {
    height: 20vh !important;

  }

}

@media (max-width:720px) {
  .main::-webkit-scrollbar {
    display: none;
  }

  .main{
    margin-top: 1em ;
  }

  .nav {
    height: 10vh !important;


  }
  
}
@media (max-width:720px) and (orientation : landscape)  {
  .main::-webkit-scrollbar {
    display: none;
  }

  .main{
    margin-top: 0.4em ;
  }

  .nav {
    height: 12vh !important;
   

  }
  
}

@media (max-width:670px) and (orientation : landscape)  {
  .main::-webkit-scrollbar {
    display: none;
  }

  .main{
    // margin-top: 0.4em ;
  }

  .nav {
    height: 15vh !important;
  

  }
  
}

@media (max-width:660px) and (orientation : landscape)  {
  .main::-webkit-scrollbar {
    display: none;
  }

  .main{
    // margin-top: 0.4em ;
  }

  .nav {
    height: 20vh !important;
    
    

  }
  
}
</style>