import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';


import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

export const bus = new Vue();
export const nav = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  // created() {
  //   const auth = getAuth();
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       localStorage.setItem('user_id', JSON.stringify(user.uid));
  //     }
     
  //   });
  // },
  render: (h) => h(App),
}).$mount('#app');
