import Vue from 'vue';

import VueRouter from 'vue-router';

import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    alias: '/startup',
    component: HomeView,
    children: [
      {
        path: '', // default view
        name: 'Home',
        // meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/components/Home/index'),
      },
      {
        path: '/register',
        name: 'Register',
        meta: { requiresGuest: true },
        component: () =>
          import(
            /* webpackChunkName: "register" */ '../components/Home/RegisterView.vue'
          ),
      },
      {
        path: '/about',
        name: 'About',
       
        component: () =>
          import(
            /* webpackChunkName: "register" */ '@/components/Home/about'
          ),
      },
      {
        path: '/login',
        name: 'Login',
        meta: { requiresGuest: true },
        component: () =>
          import(/* webpackChunkName: "register" */ '../components/Home/LoginView.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/ProfileView.vue'),

    children: [
      {
        path: '', // default view
        name: 'DashboardHome',
        
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@/components/Dashboard/index'
          ),
      },
      {
        path: '/info', // default view
        name: 'CompanyInfo',
        // meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@/components/Dashboard/companyInfo'
          ),
      },
      {
        path: '/company-kyc/', // default view
        name: 'CompanyKyc',
    
        // meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@/components/Dashboard/companyKyc'
          ),
      },

      {
        path: '/founder-info', // default view
        name: 'FounderInfo',
        // meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@/components/Dashboard/founderInfo'
          ),
      },
      {
        path: '/business-info', // default view
        name: 'BusinessInfo',
        // meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@/components/Dashboard/businessInfo'
          ),
      },

    ],
  },

  {
    path: "/error 404",
    name: "NotFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
  },
  {
    path: "*",
    redirect: { name: "NotFound" },
  },
  {
    path: "/emh",
    name: "EmailHandler",
    props: true,
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/AuthEmailHandler.vue"),

  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  let User = JSON.parse( localStorage.getItem('activeUser'))
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (User) {
      next();
    } else {
      next({ path: "/login", query: { redirect: to.fullPath } });
      
    }
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (!User) {
      next();
    } else {
      next({ path: "/dashboard", query: { redirect: to.fullPath } });
      
    }
  } else {
    next();
  }
});


export default router;
